import email from "../assets/assets/email.png"
import mobile from "../assets/assets/mobile.png"
import api from "../assets/assets/api.png"
import cpp from "../assets/assets/cpp.png"
import css from "../assets/assets/css.png"
import figma from "../assets/assets/figma.png"
import flutter from "../assets/assets/flutter.png"
import git from "../assets/assets/git.png"
import graphql from "../assets/assets/graphql.png"
import html from "../assets/assets/html.png"
import javascript from "../assets/assets/javascript.png"
import mu5 from "../assets/assets/mu5.png"
import node from "../assets/assets/node.png"
import python from "../assets/assets/python.png"
import react from "../assets/assets/react.png"
import redux from "../assets/assets/redux.png"
import sass from "../assets/assets/sass.png"
import typescript from "../assets/assets/typescript.png"
import vue from "../assets/assets/vue.png"

import about01 from "../assets/assets/about01.png"
import about02 from "../assets/assets/about02.png"
import about03 from "../assets/assets/about03.png"
import about04 from "../assets/assets/about04.png"

import profile from "../assets/assets/profile.png"
import circle from "../assets/assets/circle.svg"
import logo from "../assets/assets/logo-romainb.png"

import adidas from "../assets/assets/adidas.png"
import amazon from "../assets/assets/amazon.png"
import asus from "../assets/assets/asus.png"
import bolt from "../assets/assets/bolt.png"
import nb from "../assets/assets/nb.png"
import skype from "../assets/assets/skype.png"
import spotify from "../assets/assets/spotify.png"

import cartoon from "../assets/assets/cartoon.png"
import discord from "../assets/assets/discord.png"
// eslint-disable-next-line
export default {
  email,
  mobile,
  api,
  cpp,
  css,
  figma,
  flutter,
  git,
  graphql,
  html,
  javascript,
  mu5,
  node,
  python,
  react,
  redux,
  sass,
  typescript,
  vue,
  about01,
  about02,
  about03,
  about04,
  profile,
  circle,
  logo,
  adidas,
  amazon,
  asus,
  bolt,
  nb,
  skype,
  spotify,
  cartoon,
  discord,
}
