import React from "react"
import { BsLinkedin, BsGithub } from "react-icons/bs"
import { FaMailBulk } from "react-icons/fa"

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a
          href="https://www.linkedin.com/in/romain-b-2a1b16153/"
          target="_blank"
          rel="noreferrer"
        >
          <BsLinkedin />
        </a>
      </div>
      <div>
        <a href="https://github.com/Raivals" target="_blank" rel="noreferrer">
          <BsGithub />
        </a>
      </div>
      <div>
        <a href="mailto:romain71.contact@gmail.com" rel="noreferrer">
          <FaMailBulk />
        </a>
      </div>
    </div>
  )
}

export default SocialMedia
